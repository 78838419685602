import React, { useState } from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import SettingsContent from 'components/Page/Toolbox/SettingsContent';
import { updateUser } from 'services/userService';
import { formSubmittedEvent } from 'services/dataLayerService';
import userToolBoxJwt from 'hooks/useToolboxStorage';
import UserContext from 'utils/UserContext';

const ToolboxSettings = ({ data }) => {
  const { seo: seoContent } = data.contentfulContentHub;
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isWaiting, setIsWaiting] = useState(true);

  const saveUser = async ({ bookmarks, ...userData }, setUserInfo, openLoginModal) => {
    setIsWaiting(true);
    const jwt = userToolBoxJwt.get();
    try {
      await updateUser(jwt, userData);

      formSubmittedEvent({
        form_type: 'Toolbox User Data',
        ...userData,
      });

      setSuccessMessage('Account Settings saved.');
      setUserInfo({ ...userData, bookmarks });
    } catch (e) {
      if (e?.response?.data?.error?.toLowerCase() === 'jwt is expired') {
        openLoginModal();
        setErrorMessage('Please log-in and save again.');
      } else {
        setErrorMessage(e.response.data);
      }
    }
    setIsWaiting(false);
  };

  return (
    <ToolboxLayout contentfulSeo={seoContent} footer={null} header={null} layout="toolbox">
      <UserContext.Consumer>
        {({ userInfo, setUserInfo, openLoginModal }) => {
          return (
            userInfo && (
              <SettingsContent
                isWaiting={false}
                userInfo={userInfo}
                onSave={(data) => saveUser(data, setUserInfo, openLoginModal)}
                errorMessage={errorMessage}
                successMessage={successMessage}
              />
            )
          );
        }}
      </UserContext.Consumer>
    </ToolboxLayout>
  );
};

export default ToolboxSettings;

export const ToolBoxSettingsPage = graphql`
  query ToolBoxSettingsPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      seo {
        ...Seo
      }
    }
  }
`;
