import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from 'components/Core/FormControl';
import { Col, Row } from 'components/Core/Grid';
import { getFormValid } from 'utils/formUtils';
import useIndustries from 'hooks/useIndustries';
import Waiting from 'components/Page/Toolbox/Waiting';
import Text from 'components/Core/Text';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import { FormRow, FormCol, RightCol } from '../SecuritySettingsContent/styles';
import { ToolboxSettingsSectionLayout } from './styles';
import IndustrySelect from '../IndustrySelect';
import IndustrySelectOther from '../IndustrySelectOther';
import RoleSelect from '../RoleSelect';
import TechCountSelect from '../TechCountSelect';
import OfficeCountSelect from '../OfficeCountSelect';
import SettingsMenu from '../SettingsMenu';

const SettingsContent = ({
  isWaiting,
  userInfo = {},
  onSave,
  errorMessage,
  successMessage,
  title = 'Account Settings',
  ...otherProps
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    isValid,
    touched,
    submitCount,
  } = otherProps;

  const formValid = getFormValid({ errors, submitCount });

  const { industryList, otherIndustryList } = useIndustries();

  return (
    <ToolboxSettingsSectionLayout>
      <Row>
        <Col md={6}>
          <SettingsMenu selected={title} userInfo={userInfo} />
        </Col>
        <Col md={18}>
          {isWaiting && <Waiting />}
          {!isWaiting && (
            <>
              <Text type="h5" color="white">
                {title}
              </Text>
              <div>
                <FormRow>
                  <FormCol>
                    <TextField
                      type="text"
                      id="firstName"
                      placeholderText="First Name"
                      showOnlyFormError
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      formValid={formValid}
                      formErrors={errors}
                    />
                  </FormCol>
                  <FormCol>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        id="lastName"
                        placeholderText="Last Name"
                        showOnlyFormError
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        id="phone"
                        format="(###) ###-####"
                        placeholderText="Phone"
                        showOnlyFormError
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                  <FormCol>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        id="email"
                        placeholderText="Email"
                        showOnlyFormError
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        formValid={formValid}
                        formErrors={errors}
                        disabled={values.isSocial}
                      />
                    </FormControl>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        id="company"
                        placeholderText="Company Name"
                        showOnlyFormError
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                  <FormCol>
                    <FormControl fullWidth>
                      <IndustrySelect
                        id="industry"
                        placeholder="Industry"
                        showOnlyFormError
                        values={values}
                        handleChange={(id, value) => setFieldValue(id, value)}
                        formValid={formValid}
                        formErrors={errors}
                        options={industryList}
                      />
                    </FormControl>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <FormControl fullWidth>
                      <RoleSelect
                        id="companyRole"
                        placeholder="Role"
                        showOnlyFormError
                        values={values}
                        handleChange={(id, value) => setFieldValue(id, value)}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                  <FormCol>
                    {values.industry && values.industry === 'Other' && (
                      <FormControl fullWidth>
                        <IndustrySelectOther
                          id="industryOther"
                          placeholder="Select Other Industry"
                          values={values}
                          handleChange={(id, value) => setFieldValue(id, value)}
                          formValid={formValid}
                          formErrors={errors}
                          options={otherIndustryList}
                        />
                      </FormControl>
                    )}
                  </FormCol>
                </FormRow>
                {values.companyRole && values.companyRole !== 'Field Tech' && (
                  <FormRow>
                    <FormCol>
                      <FormControl fullWidth>
                        <TechCountSelect
                          id="techCount"
                          placeholder="How many technicians work at your company?"
                          showOnlyFormError
                          values={values}
                          handleChange={(id, value) => setFieldValue(id, value)}
                          formValid={formValid}
                          formErrors={errors}
                        />
                      </FormControl>
                    </FormCol>
                    <FormCol>
                      <FormControl fullWidth>
                        <OfficeCountSelect
                          id="officeCount"
                          placeholder="How many full-time office employees (eg. manager, dispatchers, CSRs, etc) work
                          at your company?"
                          showOnlyFormError
                          values={values}
                          handleChange={(id, value) => setFieldValue(id, value)}
                          formValid={formValid}
                          formErrors={errors}
                        />
                      </FormControl>
                    </FormCol>
                  </FormRow>
                )}
                <FormRow>
                  <FormCol style={{ margin: 'auto' }}>
                    <p className="input-failed-value">{errorMessage}</p>
                    <p className="input-success-value">{successMessage}</p>
                  </FormCol>
                  <RightCol md={12}>
                    <Button
                      type="secondary"
                      width="206"
                      shadow={false}
                      button
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  </RightCol>
                </FormRow>
              </div>
            </>
          )}
        </Col>
      </Row>
    </ToolboxSettingsSectionLayout>
  );
};

const vSchema = Yup.object().shape({
  email: Yup.string().email('Not valid email').required('Email is required'),
  firstName: Yup.string().trim().required('First Name is required').nullable(),
  lastName: Yup.string().trim().required('Last Name is required').nullable(),
  phone: Yup.string().required('Phone is required').min(14, 'Phone must be 10 numbers').nullable(),
  company: Yup.string().trim().required('Company is required').nullable(),
  companyRole: Yup.string().required('Company Role is required').nullable(),
  industry: Yup.string().required('Industry is required').nullable(),
  industryOther: Yup.string().when('industry', {
    is: 'Other',
    then: Yup.string().required('Other Industry is required'),
  }),
  techCount: Yup.string().when('companyRole', {
    is: (value) => value !== 'Field Tech',
    then: Yup.string().required('Tech Count is required'),
  }),
  officeCount: Yup.string().when('companyRole', {
    is: (value) => value !== 'Field Tech',
    then: Yup.string().required('Office Count is required'),
  }),
});

export default withFormik({
  validationSchema: vSchema,
  mapPropsToValues: ({ userInfo }) => userInfo,
  handleSubmit: (values, { props, setErrors }) => {
    props.onSave(values);
  },
})(SettingsContent);
