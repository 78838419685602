import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const ToolboxSettingsSectionLayout = styled(SectionLayout)`
  background-color: var(--darkest);
  color: white;
  .section-layout--inner-container {
    padding-top: 100px;
  }
  .input-failed-value {
    color: var(--critical-3);
  }
  .input-success-value {
    color: var(--white);
  }
  min-height: 100vh;
  h5 {
    font-weight: var(--font-weight-500);
  }
  .waiting-container {
    height: 100%;
  }
  @media (max-width: 1199px) {
    .section-layout--inner-container {
      padding-top: 60px;
    }
  }

  @media (max-width: 767px) {
    .section-layout--inner-container {
      padding-top: 50px;
    }
  }

  @media (max-width: 575px) {
    .section-layout--inner-container {
      padding: 50px 20px 55px;
    }
  }
`;
