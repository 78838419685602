import React from 'react';
import Select from 'components/Core/Select';

const RoleSelect = (props) => (
  <Select
    {...props}
    options={['Owner', 'Field Tech', 'Dispatcher', 'CSM'].map((x) => ({ value: x, label: x }))}
  />
);

export default RoleSelect;
